import { Footer as BaseFooter, FooterLink } from '@blackbird/ui-base';
import { Version } from '@blackbird/ui-base/contexts';

import { useTranslation } from '@/hooks/use-translation';

import SystemStatus from './system-status';

const infoSiteURI = 'https://www.factbird.com';

type FooterProps = {
  showSupportLink?: boolean;
};

const Footer: React.FC<FooterProps> = ({ showSupportLink = false }) => {
  const { t } = useTranslation(['shared']);
  const { commit, version } = Version.use();

  const links: FooterLink[] = [
    {
      href: 'https://status.blackbird.online',
      label: <SystemStatus />,
    },
    {
      href: '/getting-started',
      label: t('shared:gettingStarted', 'Getting started'),
    },
    {
      href: '/help',
      label: t('shared:help', 'Help'),
    },
    {
      href: 'https://www.notion.so/factbird/What-s-New-in-Blackbird-50a9562cf70d48429b5f359598645504',
      label: t('shared:whatsNew', "What's new"),
    },
    {
      href: `${infoSiteURI}/about-us`,
      label: t('shared:about', 'About'),
    },
    {
      href: `${infoSiteURI}/contact`,
      label: t('shared:contact', 'Contact'),
    },
  ];

  if (showSupportLink) {
    links.push({
      href: `${infoSiteURI}/resources/support`,
      label: t('shared:support', 'Support'),
    });
  }

  return <BaseFooter links={links} buildInfo={{ commit: commit || '', version: version || '' }} />;
};

export default Footer;
