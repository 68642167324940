import { LocalStorage, SessionStorage, Snackbar } from '@blackbird/ui-base';
import { Version } from '@blackbird/ui-base/contexts';
import { FC } from 'react';

import ApolloProvider from '@/components/apollo-provider';
import WebSerialAPI from '@/lib/serial/web-serial-api';

const StaticContextProviders: FC = ({ children }) => {
  return [ApolloProvider, WebSerialAPI, Version.default, Snackbar, LocalStorage, SessionStorage].reduce(
    (Providers, Provider) => <Provider>{Providers}</Provider>,
    <>{children}</>,
  );
};

export default StaticContextProviders;
