import { COOKIE_SESSION_NAME, SESSION_STORAGE_TOKEN } from '@blackbird/ui-base/constants';
import { useSessionStorage } from '@blackbird/ui-base/hooks';
import { Close as CloseIcon } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { useCallback, useContext, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import Cookies from 'universal-cookie';

import { UserContext } from '@/contexts/user';

const useStyles = makeStyles()((theme: Theme) => ({
  alignRight: {
    textAlign: 'right',
  },
  light: {
    color: theme.palette.getContrastText('#fff'),
  },
  dark: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    overflow: 'hidden',
  },
  loggedInInfo: {
    lineHeight: '90%',
    verticalAlign: 'middle',
  },
  loggedInEmail: {
    fontSize: 10,
  },
  assumedSpan: {
    marginTop: -1,
    color: theme.palette.secondary.main,
    fontSize: 10,
    verticalAlign: 'middle',
    '&:hover, &:hover *': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      cursor: 'pointer',
    },
  },
}));

type UserInfoProperties = {
  className?: string;
  backgroundTone?: 'dark' | 'light' | undefined;
  alignRight?: boolean;
};

// NOTE: This is simply a hacky way to figure out if the user has a session (not to mistake assumed sessions for
// dashboards). If we attempt to set a new value for accessToken which is httpOnly some browsers ignore the operation if
// the cookie already exists. We cannot read the cookie itself because it's httpOnly.
const existingSession = (uc: Cookies) => {
  uc.set('accessToken', 'test', { path: '/' });

  return uc.get('accessToken') ? (uc.remove('accessToken'), false) : true;
};

const UserInfo = ({ className, backgroundTone, alignRight }: UserInfoProperties) => {
  const tone = backgroundTone || 'dark';

  const { classes, cx } = useStyles();
  const user = useContext(UserContext);

  const uc = useMemo(() => new Cookies(), []);
  const [token, setToken] = useSessionStorage(SESSION_STORAGE_TOKEN, undefined);
  const isAssumed = token && existingSession(uc);

  const clearToken = useCallback(() => {
    setToken(undefined);
    uc.remove(COOKIE_SESSION_NAME);

    location.reload();
  }, [setToken]);

  if (!user.sub) {
    return null;
  }

  const fullName = [user.givenName, user.familyName].join(' ');

  return (
    <Typography
      variant={'body2'}
      className={cx(className, classes.loggedInInfo, classes[tone], classes.text, { [classes.alignRight]: alignRight })}
      sx={{ display: { xs: 'none', md: 'inline-block' } }}
    >
      {isAssumed ? (
        <>
          <Tooltip title={'Drop session'}>
            <span onClick={clearToken} className={classes.assumedSpan}>
              Assumed <CloseIcon className={classes.assumedSpan} />
            </span>
          </Tooltip>
          <br />
        </>
      ) : null}
      {fullName}
      <br />
      <span className={classes.loggedInEmail}>{user.email}</span>
    </Typography>
  );
};

export default UserInfo;
